<template>
  <main class="requirements-page container">
    <div class="requirements-page__inner-wrapper common-page-wrapper">
      <bread-crumbs ref="breadcrumbs" />
      <h1 class="requirements-page__title page-title">Требования к авторам</h1>
      <h3 class="requirements-page__subtitle">Дорогие читатели!</h3>
      <p class="requirements-page__par">
        Приглашаем к сотрудничеству преподавателей, аспирантов, соискателей ученого звания, учителей
        физической культуры, практических работников, интересующихся актуальными проблемами
        физического воспитания и детско-юношеского спорта.
      </p>
      <p class="requirements-page__par">
        Методические материалы и статьи присылаются по электронной почте RSS_2004@mail.ru (c
        пометкой «журнал»).
        <br />
        Для методических материалов – название файла должно включать в себя фамилию первого автора и
        название города, из которого были присланы материалы (пример: Иванов, Иркутск), объем не
        должен превышать 20 страниц текста, набранного в редакторе Microsoft Word шрифтом Times New
        Roman, 14 кегль, межстрочный интервал 1,0, формат А4, отступы слева, справа, снизу, сверху
        страницы по 2 см. Ссылки на рисунки и таблицы в круглых скобках (табл. 1), на литературу в
        квадратных [1].В правом верхнем углу публикуются фамилии и инициалы авторов. К публикации
        будут приниматься отредактированные и правильно оформленные тексты. Редакционная коллегия
        оставляет за собой право отбора и отказа в публикации статей в случае несоответствия их
        тематике журнала. Вместе с публикациями необходимо предоставить информацию об авторах,
        включающую в себя: Ф.И.О. (полностью); место работы; рабочий или домашний адрес, куда будет
        выслан авторский экземпляр журнала; контактный телефон, факс; адрес электронной почты;
        должность; ученая степень, звание. При написании и оформлении статей редакция журнала просит
        придерживаться следующих правил:
      </p>
      <p class="requirements-page__par">
        1.В структуру статьи должны входить: название статьи, название учреждения, где выполнена
        работа, реферат (резюме), ключевые слова на русском и английском языках, введение (краткое),
        цель исследования, материал и методы исследования, результаты исследования и их обсуждение,
        выводы или заключение, список литературы, сведения о рецензентах. Не допускаются обозначения
        в названиях статей: сообщение 1, 2 и т.д., часть 1, 2 и т.д.
      </p>
      <p class="requirements-page__par">
        2.Таблицы должны содержать только необходимые данные и представлять собой обобщенные и
        статистически обработанные материалы. Каждая таблица снабжается заголовком и вставляется в
        текст после абзаца с первой ссылкой на нее.
      </p>
      <p class="requirements-page__par">
        3.Количество графического материала должно быть минимальным (не более 5 рисунков). Каждый
        рисунок должен иметь подпись (под рисунком), в которой дается объяснение всех его элементов.
        Для построения графиков и диаграмм следует использовать программу Microsoft Office Excel.
        Каждый рисунок вставляется в текст как объект Microsoft Office Excel.
      </p>
      <p class="requirements-page__par">
        4.Библиографические ссылки в тексте статьи следует давать в квадратных скобках в
        соответствии с нумерацией в списке литературы. Список литературы для оригинальной статьи –
        не менее 5 и не более 10 источников. Для научного обзора – не более 50 источников. Список
        литературы составляется в алфавитном порядке: сначала отечественные, затем зарубежные авторы
        и оформляется в соответствии с ГОСТ Р 7.0.5 2008.
      </p>
      <p class="requirements-page__par">
        5.Объем статьи не должен превышать 10 страниц А4 формата, интервал 1, включая аннотацию (10
        шрифт жирный Times New Roman, интервал 1), основное содержание статьи и список литературы (1
        страница – 2000 знаков, шрифт – 14 Times New Roman, интервал – 1.5, поля: слева, справа,
        верх, низ – 2см), включая таблицы, схемы, рисунки и список литературы. Публикация статьи,
        превышающей объем в 10 стр., возможна при условии доплаты.
      </p>
      <p class="requirements-page__par">
        6.При предъявлении статьи необходимо сообщать индексы статьи (УДК) по таблицам Универсальной
        десятичной классификации, имеющейся в библиотеках.
      </p>
      <p class="requirements-page__par">
        7.К работе должен быть приложен краткий реферат (резюме) статьи на русском и английском
        языках. Объем реферата должен включать минимум 100-250 слов (по ГОСТ
      </p>
      <p class="requirements-page__par">
        7.9-95 – 850 знаков, не менее 10 строк, Реферат объемом не менее 10 строк должен кратко
        излагать предмет статьи и основные содержащиеся в ней результаты. Реферат подготавливается
        на русском и английском языках. Используемый шрифт – полужирный, размер шрифта – 10 пт.
        Реферат на английском языке должен в начале текста содержать заголовок (название) статьи,
        инициалы и фамилии авторов также на английском языке.
      </p>
      <p class="requirements-page__par">
        8.Редакция оставляет за собой право на сокращение и редактирование статей. Редакция журнала
        поддерживает постоянную связь со своими читателями. Присылайте свои интересные статьи и
        методические материалы, которые мы опубликуем в нашем издании.
      </p>
      <p class="requirements-page__par">
        <b>Гонорары за предоставляемые статьи не выплачиваются!!!</b>
      </p>
    </div>
    <partners-block />
  </main>
</template>

<script>
import PartnersBlock from '@/blocks/PartnersBlock.vue';
import BreadCrumbs from '@/components/BreadCrumbs.vue';

export default {
  name: 'RequirementsPageView',
  components: {
    PartnersBlock,
    BreadCrumbs,
  },
  data() {
    return {};
  },
  cards: [
    {
      title: 'Сборник за 2007',
      link: '#',
      img: 'journal-light.jpg',
    },
    {
      title: 'Сборник за 2007',
      link: '#',
      img: 'journal.jpg',
    },
    {
      title: 'Сборник за 2007',
      link: '#',
      img: 'journal.jpg',
    },
  ],
  type: 'collections',
  mods: ['collections'],
  mounted() {
    document.title = `${process.env.VUE_APP_TITLE} - Сборники`;
  },
  methods: {},
  computed: {},
};
</script>

<style lang="scss">
.requirements-page {
  text-align: left;
  &__title {
    &:before {
      top: -2px;
      background: $green;
      margin-right: 16px;
    }
  }

  &__subtitle {
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 32px;
    text-transform: uppercase;
  }

  &__par {
    font-weight: 400;
    font-size: 16px;
    line-height: 32px;
    color: $par;
    margin-bottom: 32px;

    b {
      color: $black;
    }
  }
}
</style>
