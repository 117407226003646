<template>
  <main class="leaders-page container">
    <div class="leaders-page__inner-wrapper transparent-page-wrapper">
      <bread-crumbs ref="breadcrumbs" />
      <h1 class="leaders-page__title page-title">Руководители отделений</h1>
      <b-table-simple class="leaders-page__table" bordered>
        <b-thead>
          <b-tr>
            <b-th
              v-for="(field, i) in fields"
              :key="`leaders-table_head-${i}`"
              class="leaders-page__table-head"
            >
              {{ field }}
            </b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr
            v-for="(row, rowIndex) in rows"
            :key="`leaders-table_row-${rowIndex}`"
            class="leaders-page__table-row"
          >
            <b-td
              class="leaders-page__table-row-item"
              v-for="(cell, cellIndex) in row"
              :key="`leaders-cells-index-${cellIndex}`"
            >
              {{ cell }}
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </div>
    <partners-block />
  </main>
</template>

<script>
import PartnersBlock from '@/blocks/PartnersBlock.vue';
import BreadCrumbs from '@/components/BreadCrumbs.vue';

export default {
  name: 'LeadersPageView',
  components: {
    PartnersBlock,
    BreadCrumbs,
  },
  data() {
    return {
      rows: [
        {
          name: 'Абаев Алан Михайлович',
          unit: 'Московское областное региональное отделение ОГФСО «Юность России»',
        },
        {
          name: 'Айвазов Степан Михайлович',
          unit: 'Ростовское областное региональное отделение ОГФСО "Юность России"',
        },
        {
          name: 'Ахмадуллин Фарид Абдулхаевич',
          unit: 'Татарское республиканское региональное отделение ОГФСО "Юность России"',
        },
        {
          name: 'Беляев Владимир Викторович',
          unit: 'Челябинское областное региональное отделение ОГФСО "Юность России"',
        },
        {
          name: 'Свиридов Вячеслав Викторович ',
          unit: 'Тамбовское региональное отделение ОГФСО "Юность России"',
        },
        {
          name: 'Варфоламеев Николай Викторович',
          unit: 'Советник президента Олимпийского Комитета',
        },
        {
          name: 'Винник Марина Александровна',
          unit: 'Волгоградское региональное отделение ОГФСО "Юность России"',
        },
        {
          name: 'Гиоев Вячеслав Ахметкаевич',
          unit: 'Северо-Осетинское региональное отделение ОГФСО «Юность России»',
        },
        {
          name: 'Громыко Юрий Иванович',
          unit: 'Свердловское областное региональное отделение ОГФСО "Юность России"',
        },
        {
          name: 'Дмитриева Анна Львовна',
          unit: 'Зам. директора Департамента развития физической культуры и массового спорта Минспорта РФ',
        },
        {
          name: 'Земцов Геннадий Михайлович',
          unit: 'Зам. директора Департамента развития физической культуры и массового спорта Минспорта РФ',
        },
        {
          name: 'Курачинов Хасан Шамсудинович',
          unit: 'Липецкое областное региональное отделение ОГФСО "Юность России"',
        },
        {
          name: 'Королев Николай Николаевич',
          unit: 'Карачаево-Черкесское республиканское региональное отделение ОГФСО "Юность России"',
        },
        {
          name: 'Кулаев Владимир Анатольевич',
          unit: 'Орловское областное региональное отделение ОГФСО "Юность России"',
        },
        {
          name: 'Лисов Олег Александрович',
          unit: 'Оренбургское региональное отделение ОГФСО "Юность России"',
        },
        {
          name: 'Олег Александрович',
          unit: 'Рязанское областное региональное отделение ОГФСО «Юность России»',
        },
        {
          name: 'Минаев Александр Владимирович',
          unit: 'Департамент государственной политики в сфере воспитания детей и молодежи Минобрнауки РФ, Начальник отдела «Развития физической культуры, спорта и здорового образа жизни»',
        },
        {
          name: 'Ремизов Юрий Серафимович',
          unit: 'Брянское региональное отделение ОГФСО "Юность России"',
        },
        {
          name: 'Сбродов Василий Федорович',
          unit: 'Курское областное региональное отделение ОГФСО «Юность России»',
        },
        {
          name: 'Смолин Василий Васильевич',
          unit: 'Владимирское областное региональное отделение ОГФСО «Юность России»',
        },
        {
          name: 'Страдзе Александр Эдуардович',
          unit: 'Директор Департамента государственной политики в сфере воспитания детей и молодежи',
        },
        {
          name: 'Сухомлинов Игорь Васильевич',
          unit: 'Кабардино-Балкарское региональное отделение ОГФСО "Юность России"',
        },
        {
          name: 'Федченко Николай Семенович',
          unit: 'Федерального государственного бюджетного учреждения «Федеральный центр организационно-методического обеспечения физического воспитания»',
        },
        {
          name: 'Хайров Ринат Асымович',
          unit: 'Мордовское республиканское региональное отделение ОГФСО "Юность России"',
        },
        {
          name: 'Шихов Владимир Павлинович',
          unit: 'Санкт-Петербургское региональное отделение ОГФСО "Юность России"',
        },
        {
          name: 'Шванберг Светлана Владимировна',
          unit: 'Начальник отдела детско-юношеского и школьного спорта',
        },
        {
          name: 'Юнаковский Александр Николаевич',
          unit: 'Башкирское республиканское региональное отделение ОГФСО «Юность России»',
        },
        {
          name: 'Перченкова Ксения Андреевна',
          unit: 'Зам. председателя Тверского регионального отделения ОГФСО «Юность России»',
        },
      ],
      fields: ['ФИО', 'Должность'],
    };
  },
  mounted() {
    document.title = `${process.env.VUE_APP_TITLE} - Попечительский совет`;
  },
  methods: {},
  computed: {},
};
</script>

<style lang="scss">
.leaders-page {
  &__title {
    &:before {
      background: $green;
      top: -2px;
    }
  }
  &__table-head {
    font-weight: 700;
    font-size: 14.04px;
    line-height: 20px;
    padding: 14px 16px !important;

    &:nth-child(1) {
      width: 27vw;
    }
  }

  &__table-row-item {
    font-weight: 500;
    font-size: 14.04px;
    line-height: 20px;
    padding: 14px 16px !important;
  }
}
</style>
