<template>
  <main class="statute-page container">
    <div class="statute-page__inner-wrapper common-page-wrapper">
      <bread-crumbs ref="breadcrumbs" />
      <div class="statute-page__title page-title">Устав объединения</div>
      <div class="statute-page__subtitle">1. ОБЩИЕ ПОЛОЖЕНИЯ</div>
      <p class="statute-page__item">
        1.1 Общественно-государственное физкультурно-спортивное объединение «Юность России»,
        именуемое в дальнейшем «Объединение», является общественной организацией, созданной с
        участием государства в соответствии с распоряжением Правительства Российской Федерации от
        11.07.2002 г. No 953-р.
      </p>
      <p class="statute-page__item">
        В соответствии с распоряжениями Правительства Российской Федерации от 11.07.2002 г. No
        953-р, от 08.12.2018 г. No 2730-р и Постановлением Правительства
      </p>
      <p class="statute-page__item">
        Российской Федерации от 28 июля 2018 г. No 884 «Об утверждении Положения о Министерстве
        просвещения Российской Федерации и признании утратившими силу некоторых актов Правительства
        Российской Федерации» учредителем Объединения является Российская Федерация; его полномочия,
        и функции Учредителя осуществляет Министерство просвещения Российской Федерации.
      </p>
      <p class="statute-page__item">
        1.2 Полное наименование Объединения: общественно-государственное физкультурно-спортивное
        объединение «Юность России». Сокращенное наименование Объединения — ОГФСО «Юность России»,
        далее по тексту Объединение. Полное наименование Объединения на английском языке:
        Public-states sport association Youth of Russia. Сокращенное наименование Объединения на
        английском языке: Public association Youth of Russia.
      </p>
      <p class="statute-page__item">
        1.3 Объединение действует на основе принципов законности и гласности, добровольности,
        равноправия и самоуправления.
      </p>
      <p class="statute-page__item">
        1.4 Объединение осуществляет свою деятельность на территории более половины субъектов
        Российской Федерации в соответствии с Конституцией Российской Федерации, законодательством
        Российской Федерации и настоящим Уставом.
      </p>
      <p class="statute-page__item">
        1.5 Место нахождения постоянно действующего руководящего органа Объединения: Российская
        Федерация, г. Москва.
      </p>
      <div class="statute-page__subtitle">2. ЦЕЛИ И ПРЕДМЕТ ДЕЯТЕЛЬНОСТИ ОБЪЕДИНЕНИЯ</div>
      <p class="statute-page__item">2.1 Целями Объединения являются:</p>
      <li class="statute-page__list-item">
        участие в реализации государственной политики в области развития детско- юношеского спорта в
        Российской Федерации;
      </li>
      <li class="statute-page__list-item">
        повышение уровня физического воспитания и спортивно-массовой работы в образовательных
        организациях во внеучебное время;
      </li>
      <li class="statute-page__list-item">
        сохранение и укрепление здоровья детей и учащейся молодежи, формирование у них потребности в
        физическом совершенствовании, в здоровом образе жизни, активной трудовой деятельности,
        готовности служению Отечеству.
      </li>
      <li class="statute-page__list-item">
        участие совместно с уполномоченными органами исполнительной власти в области физической
        культуры и спорта в организации и проведении мероприятий Всероссийского
        физкультурно-спортивного комплекса «Готов к труду и обороне» (далее -комплекс ГТО); создание
        центров тестирования.
      </li>
      <p class="statute-page__item">2.2. Предметом деятельности Объединения являются:</p>
      <li class="statute-page__list-item">
        организационно-методическая помощь общеобразовательным организациям и профессиональным
        образовательным организациям по повышению уровня 2 физического воспитания и
        спортивно-массовой работы с обучающимися во внеучебное время, сохранению и укреплению их
        здоровья, формированию у них потребности в физическом совершенствовании, здоровом образе
        жизни, готовности к активной трудовой деятельности, служению Отечеству;
      </li>
      <li class="statute-page__list-item">
        участие в разработке и реализации федеральных программ развития физической культуры и
        детско-юношеского спорта среди обучающихся общеобразовательных организаций, профессиональных
        образовательных организаций, планов физкультурно-оздоровительных и спортивных мероприятий, и
        подготовке и проведении конференций, семинаров, методических секций по вопросам физического
        воспитания и развития детско- юношеского спорта;
      </li>
      <li class="statute-page__list-item">
        создание клубов физической культуры и спорта, центров здоровья в общеобразовательных
        организациях, профессиональных образовательных организациях и по месту жительства; развитие
        сети детско-юношеских спортивных школ, центров спортивного мастерства в субъектах Российской
        Федерации;
      </li>
      <li class="statute-page__list-item">
        проведение смотров физической подготовленности учащейся молодежи, конкурсов мастеров
        педагогического труда; развитие массового детско-юношеского спорта, являющегося эффективным
        средством профилактики правонарушений и преступности, алкоголизма и наркомании;
      </li>
      <li class="statute-page__list-item">
        организационно-методическое обеспечение внеучебной и внешкольной работы —
        общеобразовательных организаций и — профессиональных образовательных организаций по
        физическому воспитанию обучающихся; организационно-методическое обеспечение образовательного
        процесса по физическому воспитанию обучающихся образовательных организаций; участие в
        разработке новых образовательных технологий, средств и форм организации обучения и
        воспитания в области физической культуры и детско-юношеского спорта;
      </li>
      <li class="statute-page__list-item">
        обобщение и распространение исторического и современного опыта развития практики обучения и
        воспитания в области физической культуры и детско-юношеского спорта в России и за рубежом;
      </li>
      <li class="statute-page__list-item">
        выявление и поддержка талантливых исследователей и организаторов в области физического
        воспитания и детско-юношеского спорта; организационно-методическая помощь органам управления
        образованием, образовательным организациям в повышении квалификации педагогических и
        управленческих кадров в области физического воспитания и детско- юношеского спорта;
      </li>
      <li class="statute-page__list-item">
        участие в реализации совместно с Министерством просвещения России всероссийских и
        международных программ в области физического воспитания и развития детско-юношеского спорта;
      </li>
      <li class="statute-page__list-item">
        участие в реализации совместно с Министерством спорта Российской Федерации и Олимпийским
        комитетом России программ в области физического воспитания и развития массового спорта;
      </li>
      <li class="statute-page__list-item">
        участие в разработке, совместно с Олимпийским комитетом России и иными государственными
        органами и организациями, федеральных программ по развитию массового спорта;
      </li>
      <li class="statute-page__list-item">
        развитие и укрепление межнациональных отношений среди обучающейся молодежи, профилактика
        асоциальных явлений в обществе, противодействие терроризму, пропаганда здорового образа
        жизни.
      </li>
      <li class="statute-page__list-item">
        подготовка, совместно с Министерством обороны Российской Федерации и Общероссийской
        общественно-государственной организацией «Добровольное общество содействия армии, авиации и
        флоту России» (ДОСААФ России), учащейся молодежи к службе в армии, проведение мероприятий
        военно- спортивной и военно-туристической направленности;
      </li>
      <li class="statute-page__list-item">
        проведение фестивалей — военно-патриотической песни и пляски, национальных танцев,
        спартакиад, фестивалей среди обучающихся и работников профессиональных образовательных
        организаций, конкурсов для выявления лучших работников образовательных организаций
        различного профиля в области физической культуры и спорта;
      </li>
      <li class="statute-page__list-item">
        организация и проведение массовых физкультурно-оздоровительных и спортивных мероприятий с
        детьми, подростками и учащейся молодежью; организация и проведение Всероссийских и
        международных соревнований в рамках Объединения;
      </li>
      <li class="statute-page__list-item">
        комплектование и подготовка сборных команд — обучающихся общеобразовательных организаций,
        профессиональных образовательных организаций для участия в соревнованиях, проводимых в
        Российской Федерации и за рубежом;
      </li>
      <li class="statute-page__list-item">
        подготовка спортивного резерва для сборных команд России;
      </li>
      <li class="statute-page__list-item">
        пропаганда физической культуры и спорта, здорового образа жизни среди детей и молодежи;
      </li>
      <li class="statute-page__list-item">
        содействие развитию, совершенствованию и эффективному использованию материально-технической
        базы физической культуры и спорта системы образования Российской Федерации;
      </li>
      <li class="statute-page__list-item">
        содействие развитию рынка спортивных товаров и услуг в системе образования Российской
        Федерации;
      </li>
      <li class="statute-page__list-item">защита социальных и иных прав членов Объединения;</li>
      <li class="statute-page__list-item">
        оказание благотворительной помощи специальным детским образовательным организациям;
      </li>
      <li class="statute-page__list-item">
        организация международного сотрудничества в области физической культуры и детско-юношеского
        спорта, физкультурно-спортивного движения.
      </li>
      <li class="statute-page__list-item">
        Объединение может осуществлять любые виды физкультурно-спортивной деятельности, не
        противоречащие целям создания и законодательству Российской Федерации.
      </li>
      <div class="statute-page__subtitle">3. ПРАВОВОЙ СТАТУС</div>
      <p class="statute-page__item">
        3.1 Объединение является юридическим лицом, имеет самостоятельный баланс, расчетный и другие
        счета, круглую печать со своим полным наименованием, штампы, бланки, эмблему, флаг, вымпел и
        другие реквизиты, утвержденные в установленном порядке.
      </p>
      <p class="statute-page__item">
        Эмблема Объединения — на прямоугольном белом полотне флажок с сочетанием белого, синего и
        красного цветов, под ним аббревиатура — \ ‚ означающая начальные буквы названия Объединения
        и название Объединения; Флаг Объединения — прямоугольное полотнище белого цвета, на котором
        изображен флажок с сочетанием белого, красного и синего цветов, под ним аббревиатура — \ ‚
        означающая начальные буквы названия Объединения, и название Объединения; отношение ширины
        флага к его длине 0,9:1,45; Вымпел Объединения — на прямоугольном белом полотне со
        скошенными нижними углами вверху слова: общественно-государственное физкультурно-спортивное
        объединение «Юность России», под ним флажок с сочетанием белого, синего и красного цветов,
        под ним аббревиатура — \ ‚означающая начальные буквы названия Объединения и круги синего,
        черного красного, желтого и зеленого цветов с изображением в них соответственно фигур
        волейболистки, боксера, футболиста, лыжницы и бегуна.
      </p>
      <p class="statute-page__item">
        Символика Объединения одновременно является символикой ее структурных подразделений.
        Региональные отделения Объединения не вправе иметь собственную символику, отличную от
        символики Объединения. Объединение может от своего имени приобретать и осуществлять
        имущественные и личные неимущественные права, нести обязанности. Правоспособность
        Объединения, как юридического лица возникает со дня внесения соответствующей записи в единый
        государственный реестр юридических лиц.
      </p>
      <p class="statute-page__item">
        3.2 Объединение имеет в собственности обособленное имущество, включая имущество, переданное
        ему членами Объединения.
      </p>
      <p class="statute-page__item">
        3.3 Объединение осуществляет в соответствии с законодательством Российской Федерации
        владение, пользование и распоряжение находящимся в его собственности имуществом в
        соответствии с целями своей деятельности и назначением имущества. Объединение отвечает по
        своим обязательствам всем своим имуществом.
      </p>
      <div class="statute-page__item">3.4 Объединение имеет право:</div>
      <li class="statute-page__list-item">
        создавать отделения, открывать филиалы и представительства в субъектах Российской Федерации;
      </li>
      <li class="statute-page__list-item">
        создавать клубы физической культуры и спорта в образовательных организациях и по месту
        жительства детей и подростков, детско-юношеские спортивные школы, центры спортивного
        мастерства; свободно распространять информацию о своей деятельности;
      </li>
      <li class="statute-page__list-item">
        вносить предложения в органы государственной власти и управления по вопросам, относящимся к
        компетенции Объединения, а также участвовать в установленном порядке в выработке решений
        этими органами;
      </li>
      <li class="statute-page__list-item">
        собирать, обобщать и распространять отечественную и зарубежную информацию в области
        физической культуры и детско-юношеского спорта;
      </li>
      <li class="statute-page__list-item">
        оказывать образовательным организациям, педагогическим работникам, родителям, иным
        организациям и лицам консультативные услуги по вопросам физического воспитания и
        детско-юношеского спорта; реализовывать дополнительное образование;
      </li>
      <li class="statute-page__list-item">
        осуществлять просветительскую деятельность в области физической культуры и детско-юношеского
        спорта, включая организацию и проведение конференций, семинаров, симпозиумов, курсов и иных
        форм обучения; устанавливать и поддерживать прямые международные контакты и связи, заключать
        соглашение с иностранными неправительственными организациями, вступать в международные
        общественные организации, приобретать права и исполнять обязанности, соответствующие статусу
        этих международных общественных организаций;
      </li>
      <li class="statute-page__list-item">
        направлять в установленном порядке и принимать команды (делегации) спортсменов, специалистов
        для участия в спортивных мероприятиях, реализации совместных программ, обмена опытом и
        повышения квалификации;
      </li>
      <li class="statute-page__list-item">
        устанавливать стипендии и учреждать премии выдающимся юным спортсменам, организаторам и
        победителям конкурсов, смотров и соревнований организуемых Объединением; в установленном
        порядке в рамках Объединения проводить на территории Российской Федерации спортивные
        соревнования, в том числе международные;
      </li>
      <li class="statute-page__list-item">
        создавать условия и оказывать консультационно-методическую помощь гражданам,
        физкультурно-спортивным, общественным и иным организациям в подготовке к выполнению
        нормативов испытаний (тестов) комплекса ГТО содержащихся в государственных требованиях;
      </li>
      <li class="statute-page__list-item">
        при утверждении Объединения центром тестирования федеральным органом исполнительной власти в
        области физической культуры и спорта по согласованию с федеральным органом — исполнительной
        — власти, осуществляющим функции по выработке и реализации государственной политики и
        нормативно-правовому регулированию в области обороны, осуществлять тестирование населения по
        выполнению нормативов испытаний (тестов) комплекса ГТО;
      </li>
      <li class="statute-page__list-item">
        взаимодействовать с органами государственной власти, органами местного самоуправления,
        физкультурно-спортивными, общественными и иными организациями по внедрению комплекса ГТО,
        проведения физкультурных и спортивных мероприятий по реализации комплекса ГТО;
      </li>
      <li class="statute-page__list-item">
        осуществлять приносящую доход деятельность в том числе предпринимательскую, направленную на
        достижение уставных целей и соответствующую этим целям, а именно: осуществлять реализацию
        рекламных услуг, в том числе спонсорской рекламы, реализацию имущественных прав (в том числе
        прав использования результатов интеллектуальной деятельности и (или) средств
        индивидуализации), создавать общественные фонды, хозяйственные и коммерческие организации,
        осуществлять рекламно-спонсорскую деятельность — в соответствии с действующим
        законодательством; оказывать платные образовательные услуги в соответствии с
        законодательством Российской Федерации, учреждать средства массовой информации, осуществлять
        издательскую и полиграфическую деятельность в установленном законом порядке; осуществлять
        деятельность по организации конференций и выставок; приобретать в собственность движимое и
        недвижимое имущество;
      </li>
      <li class="statute-page__list-item">
        в установленном законом порядке осуществлять внешнеэкономическую деятельность,
        организовывать совместные проекты с зарубежными фирмами в области производства и реализации
        спортивных товаров;
      </li>
      <li class="statute-page__list-item">
        заключать контракты по выступлению спортсменов и работе тренеров за рубежом;
      </li>
      <li class="statute-page__list-item">
        представлять и защищать свои права, а также законные интересы своих членов в органах
        государственной власти, органах местного самоуправления, общественных организациях в России
        и за рубежом;
      </li>
      <li class="statute-page__list-item">
        Заключать контракты по выступлению спортсменов и работе тренеров за рубежом;
      </li>
      <div class="statute-page__item">3.5. Объединение обязано:</div>
      <li class="statute-page__list-item">
        соблюдать законодательство Российской Федерации, общепризнанные принципы и нормы
        международного права, касающиеся сферы деятельности Объединения, нормы, предусмотренные
        иными правовыми актами, а также Устав Объединения; ежегодно публиковать отчет об
        использовании своего имущества и средств или обеспечивать доступ для ознакомления с
        указанным отчетом; ежегодно информировать орган, принявший решение о государственной
        регистрации Объединения, о продолжении своей деятельности с указанием действительного места
        нахождения постоянно действующего руководящего органа, его названия и данных о руководителях
        Объединения в объеме сведений, включаемых в единый государственный реестр юридических лиц;
        предоставлять по запросу органа, принимающего решение о государственной регистрации
        общественных объединений, и Министерства просвещения Российской Федерации решения
        руководящих органов и должностных лиц Объединения, а также годовые и квартальные отчеты о
        своей деятельности в объеме сведений, предоставляемых в налоговые органы; допускать
        представителей органа, принимающего решение о государственной регистрации общественных
        объединений, и Министерства просвещения Российской Федерации на проводимые Объединением
        мероприятия; оказывать содействие представителям органа, принимающего решения о
        государственной регистрации общественных объединений, в ознакомлении с деятельностью
        Объединения в связи с достижением уставных целей и соблюдением законодательства Российской
        Федерации; информировать федеральный орган исполнительной власти, уполномоченный в области
        государственной регистрации общественных объединений, об объеме денежных средств и иного
        имущества, полученных от иностранных источников, которые указаны в пункте 6 статьи 2
        Федерального закона «О некоммерческих организациях», о целях расходования этих денежных
        средств и использования иного имущества и об их фактическом расходовании и использовании по
        форме и в сроки, которые установлены уполномоченным федеральным органом исполнительной
        власти.
      </li>
      <li class="statute-page__list-item">
        вести бухгалтерский учет и отчетность в порядке, установленном законодательством РФ;
      </li>
      <li class="statute-page__list-item">
        исполнять иные обязанности в соответствии с законодательством Российской Федерации.
        Взаимодействие Объединения с федеральными органами государственной власти, органами
        государственной власти субъектов Российской Федерации и организациями осуществляется в
        соответствии с нормативными правовыми актами Российской Федерации и заключаемыми
        соглашениями.
      </li>
      <div class="statute-page__subtitle">4. ЧЛЕНЫ ОБЪЕДИНЕНИЯ, ИХ ПРАВА И ОБЯЗАННОСТИ</div>
      <div class="statute-page__item">
        4.1 Членами Объединения могут быть граждане Российской Федерации, иностранные граждане и
        лица без гражданства, обучающиеся в общеобразовательных организациях, профессиональных
        образовательных организациях, образовательных организациях дополнительного образования
        физкультурно-спортивной направленности, работники образовательных организаций, члены их
        семей, спортсмены, занимающиеся в спортивных школах, клубах Объединения, с учетом
        нижеуказанных ограничений.
      </div>
      <div class="statute-page__item">
        4.2 Членами Объединения могут быть граждане Российской Федерации, иностранные граждане и
        лица без гражданства, законно находящиеся на территории Российской Федерации, достигшие 18
        лет, а также юридические лица - общественные организации, выразившие поддержку уставным
        целям Объединения, соответствующие требованиям, предъявляемым к участникам (членам)
        общественных организаций действующим — законодательством Российской Федерации,
        заинтересованные в достижении целей Объединения и совместном решении его задач. Участие
        (членство) в Объединении и выход из Объединения является добровольным.
      </div>
      <div class="statute-page__item">
        4.3 Прием членов в Объединение производится Комитетом Объединения по заявлению руководящих
        органов — для юридических лиц - общественных организаций, и на основании письменного
        заявления — для физических лиц. Юридические лица - общественные организации принимают
        участие в работе Объединения через делегированных ими представителей.
      </div>
      <div class="statute-page__item">
        4.4 Выдающиеся спортсмены, тренеры, судьи, работники физической культуры и спорта и иные
        лица, имеющие большие заслуги в развитии физкультурно- спортивного движения Российской
        Федерации, могут быть почетными членами Объединения. Решение о приеме в Почетные члены
        Объединения принимает Комитет Объединения.
      </div>
      <div class="statute-page__item">
        4.5 Члены Объединения имеют равные права и исполняют равные обязанности. Учет членов
        Объединения ведется Комитетом Объединения.
      </div>
      <div class="statute-page__item">4.6 Члены Объединения имеют право:</div>
      <li class="statute-page__list-item">
        избирать и быть избранными в руководящие и контрольно-ревизионные органы Объединения;
      </li>
      <li class="statute-page__list-item">
        вносить на рассмотрение руководящих органов Объединения предложения по улучшению работы
        Объединения, участвовать в обсуждении и принятии решений; получать информацию о деятельности
        Объединения;
      </li>
      <li class="statute-page__list-item">
        пользоваться в установленном порядке символикой Объединения; приоритетного пользования
        спортивными сооружениями Объединения для учебно-тренировочных занятий;
      </li>
      <li class="statute-page__list-item">
        приоритетного приобретения спортивного инвентаря, экипировки, атрибутики и иного имущества в
        отделениях, филиалах, представительствах, клубах, спортивных центрах Объединения;
      </li>
      <li class="statute-page__list-item">
        на защиту Объединением в соответствии с законодательством Российской Федерации своих
        законных интересов;
      </li>
      <li class="statute-page__list-item">добровольного выхода из состава членов Объединения;</li>
      <li class="statute-page__list-item">
        в случаях и в порядке, которые предусмотрены законом и Уставом Объединения, получать
        информацию о деятельности Объединения и знакомиться с его бухгалтерской и иной
        документацией;
      </li>
      <li class="statute-page__list-item">
        обжаловать решения органов Объединения, влекущие гражданско-правовые последствия, в случаях
        и в порядке, которые предусмотрены законом; участвовать в выработке и принятии решений
        органами Объединения в порядке, предусмотренном уставом;
      </li>
      <li class="statute-page__list-item">
        на равных началах с другими членами Объединения безвозмездно пользоваться услугами,
        оказываемыми Объединением;
      </li>
      <li class="statute-page__list-item">
        вносить предложения в любые органы Объединения по вопросам, связанным с его деятельностью; и
        другие права предусмотренные действующим законодательством.
      </li>
      <div class="statute-page__item">4.7. Члены Объединения обязаны:</div>
      <li class="statute-page__list-item">
        участвовать в образовании имущества Объединения в необходимом размере в порядке, способом и
        в сроки, которые предусмотрены Уставом Объединения; соблюдать настоящий Устав и выполнять
        решения руководящих органов Объединения;
      </li>
      <li class="statute-page__list-item">принимать участие в деятельности Объединения;</li>
      <li class="statute-page__list-item">
        заботиться об укреплении своего здоровья, регулярно заниматься физической культурой и
        спортом, пропагандировать здоровый образ жизни;
      </li>
      <li class="statute-page__list-item">
        своевременно уплачивать вступительный и членские взносы;
      </li>
      <li class="statute-page__list-item">
        способствовать повышению престижа Объединения и не совершать действий, его дискредитирующих;
      </li>
      <li class="statute-page__list-item">
        представлять в руководящие органы Объединения установленную и запрашиваемую информацию о
        своей деятельности;
      </li>
      <li class="statute-page__list-item">
        осуществлять практическое выполнение решений руководящих органов Объединения;
      </li>
      <li class="statute-page__list-item">
        не разглашать конфиденциальную информацию о деятельности Объединения;
      </li>
      <li class="statute-page__list-item">
        участвовать в принятии корпоративных решений, без которых Объединение не может продолжать
        свою деятельность в соответствии с законом, если его участие необходимо для принятия таких
        решений;
      </li>
      <li class="statute-page__list-item">
        не совершать действия. заведомо направленные на причинение вреда Объединению;
      </li>
      <li class="statute-page__list-item">
        не совершать действия (бездействие), которые существенно затрудняют или делают невозможным
        достижение целей, ради которых создано Объединение;
      </li>
      <li class="statute-page__list-item">
        соблюдать положения действующего законодательства Российской Федерации, настоящего Устава и
        выполнять решения выборных руководящих органов Объединения, принятые в рамках их
        компетенции;
      </li>
      <li class="statute-page__list-item">
        лично участвовать и активно способствовать осуществлению уставных целей Объединения;
      </li>
      <li class="statute-page__list-item">
        нести ответственность по возмещению ущерба, причиненного Объединению. И другие обязанности
        предусмотренные действующим законодательством.
      </li>
      <div class="statute-page__item">
        4.8. Член Объединения может быть исключен из него по решению Комитета, если его деятельность
        несовместима с целями и задачами Объединения, за несоблюдение Устава, а также в случае
        невыполнения решений руководящих органов и должностных лиц Объединения.
      </div>
      <p class="statute-page__item">
        Выход из Объединения члена - общественной организации - юридического лица осуществляется на
        основании решения уполномоченного органа соответствующей общественной организации —
        юридического лица, подаваемого в Комитет Объединения.
      </p>
      <p class="statute-page__item">
        Выход из Объединения члена — физического лица осуществляется на основании его письменного
        заявления, подаваемого в Комитет Объединения.
      </p>
      <p class="statute-page__item">
        Объединение не отвечает по обязательствам своих членов, равно как и члены
      </p>
      <p class="statute-page__item">Объединения не отвечают по его обязательствам.</p>
      <div class="statute-page__item">
        4.9. Члены Объединения не сохраняют прав на переданное ими в собственность Объединения
        имущество, в том числе вступительный и членские взносы.
      </div>
      <div class="statute-page__item">
        4.10 Члены Объединения не имеют права в одностороннем порядке отчуждать собственность,
        принадлежащую Объединению.
      </div>
      <div class="statute-page__item">
        4.11. Членство в общественной организации неотчуждаемо. Осуществление прав члена Объединения
        не может быть передано другому лицу.
      </div>
      <div class="statute-page__subtitle">5. СТРУКТУРНЫЕ ПОДРАЗДЕЛЕНИЯ ОБЪЕДИНЕНИЯ</div>
      <p class="statute-page__item">
        5.1 Структурными подразделениями Объединения являются региональные отделения, а так же могут
        быть филиалы и представительства.
      </p>
      <p class="statute-page__item">
        Также в структуре Объединения для осуществления им образовательной деятельности создается
        специализированное структурное образовательное подразделение, деятельность которого
        регулируется положением, утверждаемым Комитетом Объединения.
      </p>
      <p class="statute-page__item">
        5.2. Региональное отделение Объединения осуществляет свою деятельность на территории
        соответствующего субъекта Российской Федерации.
      </p>
      <p class="statute-page__item">
        5.3. Число членов Объединения, состоящих на учете в региональном отделении, не может быть
        менее 3 человек. Региональные отделения Объединения осуществляют свою деятельность на
        основании настоящего Устава, приобретают права юридического лица с момента государственной
        регистрации регионального отделения в установленном законом порядке. Решения вышестоящих
        органов Объединения обязательны для региональных отделений.
      </p>
      <p class="statute-page__item">
        5.4. Высшим руководящим органом регионального отделения Объединения является Общее собрание,
        которое проводится не реже одного раза в пять лет. Внеочередное Общее собрание может быть
        созвано по требованию не менее половины членов Объединения, состоящих на учете в
        региональном отделении.
      </p>
      <p class="statute-page__item">
        5.5. Общее собрание регионального отделения созывается Комитетом регионального отделения и
        правомочно, если в его работе принимают участие более половины членов Объединения, состоящих
        на учете в региональном отделении.
      </p>
      <p class="statute-page__item">
        5.6. Общее собрание регионального отделения может принять к рассмотрению любой вопрос
        деятельности регионального отделения.
      </p>
      <p class="statute-page__item">К исключительной компетенции Общего собрания относится:</p>
      <li class="statute-page__list-item">
        избрание Комитета регионального отделения, досрочное прекращение его полномочий, доизбрание
        членов Комитета регионального отделения;
      </li>
      <li class="statute-page__list-item">
        избрание контрольно-ревизионной комиссии, досрочное прекращение ее полномочий, доизбрание
        членов — контрольно-ревизионной — комиссии регионального отделения, назначение аудиторской
        организации или индивидуального аудитора регионального отделения;
      </li>
      <li class="statute-page__list-item">
        избрание Председателя регионального отделения, с обязательным одобрением Комитетом
        Объединения, досрочное прекращение его полномочий;
      </li>
      <li class="statute-page__list-item">
        утверждение годового отчета и бухгалтерской (финансовой) отчетности регионального отделения;
      </li>
      <li class="statute-page__list-item">
        определение количественного состава Комитета и контрольно-ревизионной комиссии, избрание
        делегатов на съезд Объединения.
      </li>
      <li class="statute-page__list-item">
        определение приоритетных направлений деятельности регионального отделения.
      </li>
      <p class="statute-page__item">
        Решения Общего собрания регионального отделения по вопросам его исключительной компетенции
        принимаются квалифицированным большинством голосов (не менее чем 2/3) от числа
        присутствующих членов Объединения, состоящих на учете в региональном отделении, при наличии
        кворума.
      </p>
      <p class="statute-page__item">
        Члены Комитета регионального отделения, председатель регионального отделения,
        контрольно-ревизионная комиссия регионального отделения избираются сроком на пять лет.
      </p>
      <p class="statute-page__item">
        Решения Общего собрания регионального отделения по иным вопросам принимаются простым
        большинством голосов присутствующих членов Объединения, состоящих на учете в региональном
        отделении, при наличии кворума. Порядок и форма голосования по всем вопросам определяются
        Общим собранием.
      </p>
      <p class="statute-page__item">
        5.7 Постоянно действующим коллегиальным руководящим органом регионального отделения является
        Комитет избираемый Общим собранием регионального отделения сроком на пять лет. В компетенцию
        Комитета регионального отделения входит: обеспечение выполнения решений Общего собрания
        регионального отделения и руководящих органов Объединения, осуществление прав и обязанностей
        юридического лица от имени регионального отделения Объединения, учет членов Объединения в
        региональном отделении. Комитет подотчетен Общему собранию регионального отделения.
        Заседания комитета правомочны при участии в нем более половины его членов. Решения Комитета
        регионального отделения принимаются простым большинством голосов присутствующих членов
        Комитета регионального отделения при наличии кворума. Комитет проводит свои заседания по
        мере необходимости, но не реже одного раза в три месяца. Заседания Комитета регионального
        отделения созываются председателем регионального отделения. Комитет избирает заместителей
        председателя регионального отделения сроком на пять лет. Заместители председателя
        регионального отделения избираются простым большинством голосов членов Комитета
        регионального отделения, при наличии кворума. Заместители председателя регионального
        отделения отвечают за направления деятельности, порученные им председателем регионального
        отделения, в случае отсутствия председателя регионального отделения один из его заместителей
        осуществляет его полномочия на основании поручения председателя регионального отделения или
        решения Комитета регионального отделения.
      </p>
      <p class="statute-page__item">
        5.8 Председатель регионального отделения является единоличным исполнительным органом и
        избирается сроком на пять лет. Председатель регионального отделения: без доверенности
        представляет региональное отделение во взаимоотношениях с органами государственной власти и
        управления, органами местного самоуправления, юридическими и физическими лицами;
        распоряжается имуществом и денежными средствами регионального отделения по согласованию с
        Комитетом регионального отделения; открывает расчетный счет в региональном казначействе
        Министерства финансов Российской Федерации, в кредитной организации; обладает правом первой
        подписи финансовых документов регионального отделения; издает приказы; осуществляет иные
        функции в пределах своей компетенции. Председатель регионального отделения подотчетен Общему
        собранию и Комитету регионального отделения.
      </p>
      <p class="statute-page__item">
        5.9Контрольно-ревизионная комиссия регионального отделения осуществляет проверки
        финансово-хозяйственной деятельности регионального отделения, Комитета регионального
        отделения, организацию работы с поступившими в их адрес документами и письмами. Председатель
        контрольно-ревизионной комиссии избирается из состава ее членов на заседании комиссии
        открытым голосованием простым большинством голосов членов комиссии на срок полномочий
        комиссии. Председатель контрольно-ревизионной комиссии проводит заседания
        контрольно-ревизионной комиссии, подписывает протоколы заседаний контрольно-ревизионной
        комиссии, распределяет обязанности между ее членами. Решения контрольно-ревизионной комиссии
        принимаются простым большинством голосов ее членов при наличии кворума.
        Контрольно-ревизионная комиссия регионального отделения подотчетна Общему собранию
        регионального отделения.
      </p>
      <p class="statute-page__item">
        Заседание контрольно-ревизионной комиссии правомочно при присутствии на нем более половины
        членов контрольно-ревизионной комиссии.
      </p>
      <p class="statute-page__item">
        5.10 В период между Общими собраниями руководство деятельностью регионального отделения
        осуществляет Комитет регионального отделения.
      </p>
      <p class="statute-page__item">
        5.11 Филиалы и представительства Объединения действуют на основании Положения о них,
        утвержденного Комитетом Объединения.
      </p>
      <p class="statute-page__item">
        5.12 Руководители филиалов и представительств назначаются решением Комитета Объединения,
        действуют на основании доверенности, выданной Объединением — заверенной нотариально.
      </p>
      <div class="statute-page__subtitle">6. ОРГАНЫ УПРАВЛЕНИЯ ОБЪЕДИНЕНИЕМ</div>
      <div class="statute-page__item">6.1 Органами управления Объединением являются:</div>
      <li class="statute-page__list-item">Съезд;</li>
      <li class="statute-page__list-item">Комитет;</li>
      <li class="statute-page__list-item">Председатель.</li>
      <div class="statute-page__item">
        6.2. Высшим руководящим органом Объединения является Съезд, который проводится не реже
        одного раза в пять лет. Внеочередной съезд может быть созван по инициативе Комитета, а также
        по требованию не менее половины членов контрольно-ревизионной комиссии Объединения. Дата
        созыва, повестка дня и норма представительства на съезде устанавливаются Комитетом и
        объявляются не позднее, чем за месяц до его проведения. Съезд считается правомочным, если в
        его работе участвуют делегаты от более половины региональных отделений Объединения. Съезд
        принимает решения простым большинством голосов присутствующих на съезде делегатов от
        региональных отделений, за исключением случаев предусмотренных Уставом. Форму голосования
        определяет съезд. Съезд принимает решения по любым вопросам деятельности Объединения.
      </div>
      <p class="statute-page__item">
        Внеочередной съезд может быть созван по инициативе комитета, а также по требованию не менее
        половины членов комитета. Дата созыва, повестка дня и норма представительства на съезде
        устанавливаются комитетом и объявляются не позднее, чем за месяц до его проведения. Съезд
        считается правомочным, если в его работе участвуют представители от более половины субъектов
        Российской Федерации. Съезд принимает решения простым большинством голосов присутствующих на
        съезде. Форму голосования определяет съезд. Для принятия решений по вопросам внесения
        изменений и дополнений в Устав, реорганизации или прекращения деятельности Объединения,
        прекращения полномочий председателя Объединения требуется 2/3 голосов присутствующих на
        съезде. Съезд принимает решения по любым вопросам деятельности Объединения.
      </p>
      <div class="statute-page__subtitle">
        6.3. К исключительной компетенции Съезда Объединения относится:
      </div>
      <li class="statute-page__list-item">
        утверждение Устава, внесение в него изменений и дополнений;
      </li>
      <li class="statute-page__list-item">
        определение приоритетных направлений деятельности Объединения, принципов образования и
        использования его имущества;
      </li>
      <li class="statute-page__list-item">
        избрание Комитета Объединения, сроком на пять лет, досрочное прекращение его полномочий,
        доизбрание членов Комитета Объединения;
      </li>
      <li class="statute-page__list-item">
        избрание Контрольно-ревизионной комиссии, сроком на пять лет, досрочное прекращение ее
        полномочий, доизбрание членов Контрольно-ревизионной комиссии, назначение аудиторской
        организации или индивидуального аудитора Объединения;
      </li>
      <li class="statute-page__list-item">
        избрание Председателя Объединения сроком на пять лет, досрочное прекращение его полномочий;
      </li>
      <li class="statute-page__list-item">
        принятие решений о реорганизации или ликвидации Объединения, о назначении ликвидационной
        комиссии (ликвидатора) и об утверждении ликвидационного баланса;
      </li>
      <li class="statute-page__list-item">
        принятие решения о создании других юридических лиц (за исключением региональных отделений
        Объединения), об участии в других юридических лицах, о создании филиалов и об открытии
        представительств;
      </li>
      <li class="statute-page__list-item">
        определение направлений использования финансовых средств;
      </li>
      <li class="statute-page__list-item">
        определение порядка приема в состав членов Объединения и исключения из числа его членов;
      </li>
      <li class="statute-page__list-item">
        принятие решения о размере и порядке уплаты членских и иных имущественных взносов членами
        Объединения; утверждение положения о членстве;
      </li>
      <li class="statute-page__list-item">избрание почетного председателя Объединения.</li>
      <p class="statute-page__item">
        Решения Съезда Объединения по вопросам его исключительной компетенции принимаются не менее
        чем 2/3 голосов от числа присутствующих делегатов Съезда при наличии кворума.
      </p>
      <div class="statute-page__item">
        6.4. В период между съездами постоянно действующим руководящим органом Объединения является
        Комитет, избираемый съездом из числа членов Объединения, сроком на пять лет, в количестве,
        устанавливаемом съездом. Председатель Объединения организует работу Комитета. Комитет
        проводит свои заседания по мере необходимости, но не реже четырех раз в год и созывается
        председателем Объединения, в его отсутствие — заместителем председателя, исполняющим его
        обязанности. Внеочередное заседание Комитета может быть созвано председателем Объединения,
        либо по требованию не мене 1/3 членов Комитета. Комитет проводит свои заседания, как в
        очной, так и заочной (посредством видеоконференции) форме. Решения Комитета правомочны при
        присутствии на его заседании более половины его членов. Решение принимаются простым
        большинством голосов. В заседаниях Комитета могут принимать участие члены
        контрольно-ревизионной комиссии, члены Объединения, не являющиеся членами Комитета, с правом
        совещательного голоса.
      </div>
      <div class="statute-page__item">
        6.5. Комитет является постоянно действующим руководящим — органом, осуществляющим права и
        обязанности юридического лица от имени Объединения, решает все вопросы, касающиеся
        деятельности Объединения, не относящиеся к исключительной компетенции Съезда.
        <div class="statute-page__item">К компетенции Комитета относится:</div>
      </div>
      <li class="statute-page__list-item">
        избрание заместителей председателя Объединения сроком на 5 лет, досрочное прекращение их
        полномочий;
      </li>
      <li class="statute-page__list-item">заслушивание отчета о работе Председателя;</li>
      <li class="statute-page__list-item">
        принятие решения о проведении очередных и внеочередных съездов Объединения, дате, месте их
        проведения и повестке дня;
      </li>
      <li class="statute-page__list-item">
        установление норм представительства делегатов от региональных отделений Объединения на
        Съезд;
      </li>
      <li class="statute-page__list-item">
        осуществление от имени Объединения права юридического лица, права собственника, либо
        пользователя имущества Объединения;
      </li>
      <li class="statute-page__list-item">
        рассмотрение и утверждение годового отчета, бухгалтерской (финансовой) отчетности;
      </li>
      <li class="statute-page__list-item">
        рассмотрение любого вопроса деятельности Объединения, не отнесенного к исключительной
        компетенции съезда;
      </li>
      <li class="statute-page__list-item">
        организация работы по реализации решений съезда и собственных решений; утверждение планов
        работы, планов физкультурно-спортивных и других мероприятий Объединения;
      </li>
      <li class="statute-page__list-item">
        осуществление иных видов деятельности и совершение иных действий, способствующих
        эффективному выполнению целей Объединения в рамках действующего законодательства;
      </li>
      <li class="statute-page__list-item">
        решение вопроса создания и ликвидации региональных отделений Объединения; назначение
        ликвидационной комиссии (ликвидатора) и утверждение ликвидационного баланса регионального
        отделения;
      </li>
      <li class="statute-page__list-item">
        прием в члены и исключение из членов Объединения, учет членов Объединения;
      </li>
      <li class="statute-page__list-item">
        одобрения Комитетом Объединения кандидатур предлагаемых на избрание исполнительных органов
        региональных отделений.
      </li>
      <div class="statute-page__item">
        6.6. Председатель Объединения является единоличным исполнительным органом и избирается
        сроком на пять лет.
      </div>
      <div class="statute-page__item">К компетенции Председателя Объединения относиться:</div>
      <li class="statute-page__list-item">
        распределение — обязанностей между — заместителями — председателя Объединения;
      </li>
      <li class="statute-page__list-item">
        представление интересов Объединения в органах государственной власти, общественных
        организациях в России и за рубежом; действует без доверенности от имени Объединения;
      </li>
      <li class="statute-page__list-item">
        несение ответственности за целевое использование финансовых и материальных средств
        Объединения, является распорядителем кредитов, открывает и закрывает счета в банках,
        казначействе Министерства финансов Российской Федерации, утверждает сметы и вносит в них
        изменения;
      </li>
      <li class="statute-page__list-item">
        принятие решений по вопросам деятельности Объединения не входящим в компетенцию других
        органов управления Объединения; подписание официальной документации Объединения; утверждение
        штатного расписания аппарата, в соответствии с законодательством Российской Федерации
        назначает и увольняет работников аппарата;
      </li>
      <li class="statute-page__list-item">
        издание приказов, распоряжений, заключение договоров, контрактов и других актов, необходимых
        для деятельности Объединения в пределах своей компетенции;
      </li>
      <li class="statute-page__list-item">
        утверждение должностных обязанностей работников аппарата;
      </li>
      <li class="statute-page__list-item">
        представление ежегодного отчета о своей деятельности в Комитет.
      </li>
      <div class="statute-page__item">
        6.7. Заместители председателя Объединения курируют различные направления деятельности
        Объединения по решению Председателя Объединения. Заместители председателя Объединения могут
        исполнять свои обязанности, как на общественных началах, так и на штатной должности; в
        случае необходимости замещают председателя.
      </div>
      <div class="statute-page__item">
        6.8. Почетный Председатель Объединения может избираться из числа лиц внесших большой личный
        вклад в развитие физической культуры и спорта. Почетный Председатель избирается Съездом
        Объединения в порядке, предусмотренном настоящим уставом. Почетный Председатель Объединения
        избирается сроком на пять лет. Почетный Председатель Объединения может участвовать в
        заседаниях Комитета Объединения и принимать активное участие в его работе с правом
        совещательного голоса.
      </div>
      <div class="statute-page__item">6.9. К компетенции почетного Председателя относится:</div>
      <li class="statute-page__list-item">участие в общественной жизни Объединения;</li>
      <li class="statute-page__list-item">
        представление ежегодного отчета о своей деятельности в Комитет.
      </li>
      <li class="statute-page__list-item">
        взаимодействие с ветеранским движением некоммерческих организаций,
      </li>
      <li class="statute-page__list-item">
        осуществляющих свою деятельность в области развития массового спорта.
      </li>
      <li class="statute-page__list-item">участие во всех мероприятиях Объединения;</li>
      <li class="statute-page__list-item">содействие росту авторитета Объединения;</li>
      <div class="statute-page__subtitle">7. ИСТОЧНИКИ ФОРМИРОВАНИЯ ИМУЩЕСТВА ОБЪЕДИНЕНИЯ</div>
      <p class="statute-page__item">
        7.1. Источниками формирования имущества Объединения в денежной и иных формах являются:
      </p>
      <li class="statute-page__list-item">
        субсидии, выделяемые ежегодно из Федерального бюджета на осуществление деятельности
        Объединения, в соответствии с его основными целями; денежные поступления из бюджетов
        субъектов Российской Федерации; вступительные и членские взносы членов Объединения;
      </li>
      <li class="statute-page__list-item">
        возможные добровольные имущественные взносы и пожертвования юридических и физических лиц;
      </li>
      <li class="statute-page__list-item">
        доходы от предпринимательской, внешнеэкономической деятельности, гражданско-правовых сделок;
      </li>
      <li class="statute-page__list-item">
        иные поступления, не запрещенные законодательством Российской Федерации;
      </li>
      <p class="statute-page__item">
        7.2. Объединение может иметь в собственности здания, строения, сооружения, жилищный фонд,
        земельные участки, транспорт, оборудование, инвентарь, имущество — физкультурно-спортивного
        и иного назначения, денежные средства, акции, другие ценные бумаги и иное имущество,
        необходимое для материального обеспечения деятельности Объединения.
      </p>
      <p class="statute-page__item">
        7.3. Объединение самостоятельно распоряжается принадлежащими ему средствами и имуществом.
      </p>
      <p class="statute-page__item">
        7.4. Доходы Объединения от деятельности, приносящей доход, в том числе предпринимательской
        деятельности, направляются на уставные цели и не подлежат перераспределению между членами
        Объединения.
      </p>
      <p class="statute-page__item">
        7.5. Объединение не отвечает по обязательствам своих членов, а члены Объединения не отвечают
        по обязательствам Объединения.
      </p>
      <p class="statute-page__item">
        7.6. Структурные подразделения (отделения) имеют право оперативного управления имуществом,
        закрепленным за ними собственником.
      </p>
      <div class="statute-page__subtitle">8. КОНТРОЛЬНО-РЕВИЗИОННАЯ КОМИССИЯ ОБЪЕДИНЕНИЯ</div>
      <p class="statute-page__item">
        8.1. Контроль над финансово-хозяйственной — деятельностью — Объединения осуществляется
        контрольно-ревизионной комиссией Объединения, избираемой съездом сроком на пять лет.
        Количественный состав контрольно-ревизионной комиссии определяется съездом. Руководство
        работой контрольно-ревизионной комиссии осуществляет ее председатель, избираемый на
        заседании контрольно-ревизионной комиссии из числа ее членов простым большинством голосов на
        срок ее полномочий. Члены контрольно-ревизионной комиссии не могут быть избраны в
        руководящие органы Объединения. Заседание контрольно-ревизионной комиссии правомочно при
        присутствии на нем более половины членов контрольно-ревизионной комиссии.
      </p>
      <p class="statute-page__item">
        8.2. Контрольно-ревизионная комиссия имеет право знакомиться со всеми документами,
        связанными с финансово-хозяйственной деятельностью Объединения, проводит ежегодные проверки
        и по их результатам информирует Комитет.
      </p>
      <p class="statute-page__item">
        8.3. Контрольно-ревизионная комиссия представляет отчеты о своей деятельности съезду.
      </p>
      <p class="statute-page__item">
        8.4. Решения контрольно-ревизионной комиссии принимаются — простым большинством при наличии
        кворума.
      </p>
      <div class="statute-page__subtitle">9. РЕОРГАНИЗАЦИЯ И ЛИКВИДАЦИЯ ОБЪЕДИНЕНИЯ</div>
      <p class="statute-page__item">
        9.1. Реорганизация и ликвидация Объединения происходит в порядке, установленном
        законодательством Российской Федерации.
      </p>
      <p class="statute-page__item">
        9.2. Реорганизация Объединения (преобразование, слияние, присоединение, разделение,
        выделение) осуществляется по решению Съезда.
      </p>
      <p class="statute-page__item">
        Имущество Объединения переходит после ее реорганизации к вновь возникшим юридическим лицам в
        порядке, предусмотренном Гражданским кодексом Российской Федерации.
      </p>
      <p class="statute-page__item">
        9.3. Ликвидация Объединения производится ликвидационной комиссией либо ликвидатором,
        избранными на Съезде.
      </p>
      <p class="statute-page__item">
        9.4. Имущество и денежные средства Объединения после удовлетворения требований кредиторов в
        соответствии с решением ликвидационной комиссии (ликвидатора) направляются на цели,
        предусмотренные Уставом Объединения.
      </p>
      <p class="statute-page__item">
        9.5. Документы по личному составу на штатных сотрудников аппарата Объединения после
        прекращения деятельности Объединения передаются в установленном порядке на государственное
        архивное хранение.
      </p>
      <p class="statute-page__item">
        9.6. Деятельность региональных отделений может быть прекращена (ликвидирована) в
        соответствии с решениями Комитета Объединения, а также по решению суда.
      </p>
      <p class="statute-page__item">
        9.7. Решение о ликвидации Объединения направляется в Министерство юстиции Российской
        Федерации для принятия решения об исключения его из единого государственного реестра
        юридических лиц.
      </p>
      <div class="statute-page__subtitle">10. ПОРЯДОК ВНЕСЕНИЯ ИЗМЕНЕНИЙ ВУСТАВ ОБЪЕДИНЕНИЯ</div>
      <p class="statute-page__item">
        10.1. Изменения вносятся в Устав по решению Съезда и принимаются квалифицированным
        большинством не менее чем 2/3 голосов присутствующих на Съезде делегатов при наличии
        кворума.
      </p>
      <p class="statute-page__item">
        10.2. Изменения в Уставе Объединения подлежат государственной регистрации в установленном
        законом порядке и приобретают юридическую силу с момента этой регистрации.
      </p>
    </div>
    <div class="statute-page__additional transparent-page-wrapper">
      <div class="statute-page__subtitle">Документы</div>
      <div class="statute-page__cards">
        <hoverable-card
          :type="'download'"
          :mods="['download']"
          :index="index"
          :data="card"
          v-for="(card, index) in docs"
          :key="`statute-page-card-${index}`"
        />
      </div>
    </div>
    <partners-block />
  </main>
</template>

<script>
import PartnersBlock from '@/blocks/PartnersBlock.vue';
import BreadCrumbs from '@/components/BreadCrumbs.vue';
import HoverableCard from '@/components/HoverableCard.vue';

export default {
  name: 'SatutePageView',
  components: {
    PartnersBlock,
    BreadCrumbs,
    HoverableCard,
  },
  data() {
    return {
      docs: [
        {
          title: 'Устав объединения',
          link: '/docs/statute.pdf',
        },
      ],
    };
  },
  mounted() {
    document.title = `${process.env.VUE_APP_TITLE} - Устав Объединения`;

    this.getStatuteData();
  },
  methods: {
    getStatuteData() {
      this.$axios
        .get(`https://sportunros.ru/wp-json/wp/v2/posts/820 `)
        .then((response) => {
          this.docs = [];
          this.docs.push({
            title: response.data.acf['документ'].title,
            link: response.data.acf['документ'].url,
          });
        })
        .catch((e) => console.error(e));
    },
  },
  computed: {},
};
</script>

<style lang="scss">
.statute-page {
  text-align: left;
  &__title {
    &:before {
      margin-right: 16px;
      top: -6px;
      background: $green;
    }
  }

  &__additional {
    .statute-page__subtitle {
      font-weight: 700;
      font-size: 24px;
      line-height: 23px;
      margin-top: 120px;
      padding-top: 40px;
      border-top: 1px solid $gray-2;
    }
  }

  &__cards {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 32px;
  }

  &__inner-wrapper {
    margin-top: 72px;
  }

  &__subtitle {
    margin-bottom: 32px;
    margin-top: 40px;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    text-transform: uppercase;
  }

  &__item,
  &__list-item {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: $par;
    margin-bottom: 24px;
  }

  &__list-item {
    list-style: none;
    padding-left: 24px;
    position: relative;
    &:before {
      content: '';
      width: 5px;
      height: 5px;
      border-radius: 50%;
      position: absolute;
      top: 10px;
      left: 9px;
      background: $par;
    }
  }
}
</style>
