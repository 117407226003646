<template>
  <main class="history-page container">
    <div class="history-page__inner-wrapper common-page-wrapper">
      <bread-crumbs ref="breadcrumbs" />
      <h1 class="history-page__title page-title" v-if="title">{{ title }}</h1>
      <div class="history-page__content wp-html-content" v-html="content" />
      <!-- <h2 class="history-page__subtitle">Как все начиналось?</h2>
      <p class="history-page__item">
        21 июля 2017 года исполнится 74 года со дня создания Всесоюзного добровольного спортивного
        общества «Трудовые резервы».
      </p>
      <p class="history-page__item">
        Организация общества тесно связана с учреждением Указом Президиума Верховного Совета СССР от
        2 октября 1940 г. Государственной системы трудовых резервов СССР — системы профессиональной
        подготовки молодежи, квалифицированного пополнения рабочего класса.
      </p>
      <p class="history-page__item">
        Система трудовых резервов заменила существовавшие до этого школы фабрично-заводского
        ученичества (ФЗУ), которые впервые появились в СССР в 1918 г., а с 1920 г. их количество в
        стране начало постоянно расти. В середине 20-х гг. XX века функционировали уже школы ФЗУ
        трех типов обучения в зависимости от сложности осваиваемых профессий и глубины получаемых
        учащимися ФЗУ знаний и умений. Но до октября 1940 г. все школы ФЗУ находились в подчинении
        отдельных предприятий и ведомств. Созданием в 1940 г. системы трудовых резервов Советское
        государство централизовало и сбалансировало подготовку и распределение квалифицированных
        кадров для важнейших отраслей народного хозяйства.
      </p>
      <p class="history-page__item">
        Руководство работой ВДСО «Трудовые резервы» осуществлялось Главным управлением трудовых
        резервов при СНК СССР по согласованию с Комитетом по делам физкультуры и спорта при СНК
        СССР, финансировалась за счет госбюджета.
      </p>
      <img class="history-page__img" src="@/assets/images/history/magazine.jpg" alt="" />
      <h3 class="history-page__year">1941</h3>
      <p class="history-page__item">
        Были учреждены ремесленные (РУ) и железнодорожные (ЖУ) училища (срок обучения 2 года по
        сложным профессиям), а также школы фабрично-заводского обучения (ФЗО), в которых в течение
        6-10 месяцев юноши и девушки осваивали массовые рабочие профессии, и всюду была введена
        единая методика производственного обучения. Были созданы при Совете Народных Комиссаров
        (СНК) СССР Главное управление трудовых резервов и сеть республиканских и местных управлений
        трудовых резервов.
      </p>
      <p class="history-page__item">
        Перед Великой Отечественной войной 1941— 1945 гг. в стране насчитывалось 1551 учебное
        заведение трудовых резервов, где обучались 602 тысячи юношей и девушек. Молодежь получила
        широкие возможности для обучения любой трудовой специальности. При этом государство приняло
        на себя материальное обеспечение всех учащихся, бесплатно снабжало их питанием,
        обмундированием, общежитием, учебной литературой. Никакой платы не существовало и за само
        обучение. Кроме подготовки квалифицированных рабочих кадров по выбранным молодыми людьми
        рабочим специальностям, перед системой трудовых резервов была поставлена задача нацеленной
        общефизической, профессионально-физической и военно-практической подготовки каждого будущего
        труженика. Этому способствовало, в частности, введение в 1940 г. новой редакции Всесоюзного
        физкультурного комплекса «Готов к труду и обороне СССР». И с первых дней зарождения
        Государственной системы трудовых резервов во всех профессиональных училищах и школах ФЗО
        развернулась активная учебная (на урочных занятиях физической культурой) и внеурочная (в
        спортивных секциях) физкультурно-спортивная работа.
      </p>
      <h3 class="history-page__year">1945</h3>
      <p class="history-page__item">
        Вероломное нападение на СССР фашистской Германии 22 июня 1941 г. в корне изменило (особенно
        в начале войны) направление деятельности ремесленных и железнодорожных училищ, школ ФЗО.
        Сотни учебных заведений системы трудовых резервов вместе с десятками тысяч учащихся были
        эвакуированы из западных регионов страны из-за угрозы их оккупации немецко-фашистскими
        войсками. По решению Государственного комитета обороны СССР профессиональные училища и школы
        ФЗО были переключены на обучение специальностям по производству продукции для действующей
        армии и ремонту поврежденной в боях военной техники. Были пересмотрены с учетом потребностей
        военного времени номенклатура изучаемых профессий и сроки обучения.
      </p>
      <p class="history-page__item">
        К концу 1945 г. в стране было 2488 профессиональных училищ и школ трудовых резервов. Всего
        за годы войны советская промышленность, транспорт и строительные организации получили из
        этих учебных заведений свыше 2480 тысяч молодых рабочих. Численность подготовленных за .
        время войны в системе трудовых резервов рабочих превысила все суммарные выпуски школ ФЗУ в
        довоенные пятилетки. Советское государство за годы Великой Отечественной войны на подготовку
        молодых рабочих в ремесленных и железнодорожных училищах, школах ФЗО израсходовало 11
        миллиардов рублей.
      </p>
      <p class="history-page__item">
        Сотни тысяч воспитанников довоенных школ ФЗУ и выпускников первых наборов ремесленных и
        железнодорожных училищ, школ ФЗО мужественно сражались на фронте, и очень многие были
        награждены боевыми орденами и медалями. Более 250 человек удостоены звания Героя Советского
        Союза. Среди них трижды Герой Советского Союза маршал авиации А.Й.Покрышкин, дважды Герои
        Советского Союза летчики С.В.Хохряков и А.Т.Карпов, Герои Советского Союза летчики
        В.В.Талалихин и А.П.Маресьев, танкист Ю.В.Смирнов. С самого начала войны значительно были
        усилены требования к военно-физической подготовке учащихся. Для этого, в частности, в
        Главном управлении трудовых резервов СНК СССР в 1942 г. был создан специальный отдел, на
        который была возложена ответственность за военное и физическое воспитание учащихся
        профессиональных училищ и школ. А еще раньше, с 1 сентября 1941 г., во всех этих учебных
        заведениях была введена обязательная подготовка к сдаче норм комплекса ГТО.
      </p>
      <p class="history-page__item">
        В учебных заведениях системы трудовых резервов было учреждено более 500 коллективов
        физической культуры. И когда 23 февраля 1943 г. был организован первый массовый лыжный
        кросс, посвященный 25-й годовщине Красной Армии, в нем приняли участие 254 тысячи юношей и
        девушек, обучающихся в профессиональных училищах и школах, и 235 тысяч из них сдали нормы
        комплекса ГТО.
      </p>
      <p class="history-page__item">
        А годом ранее, в мае 1942 г., в Москве стал функционировать первый спортивный клуб системы
        трудовых резервов. В декабре того же года Совет по военно-физической подготовке при Главном
        управлении трудовых резервов утвердил календарь массовых военно-спортивных игр,
        предусматривающий проведение смотров строевой подготовки, военизированных эстафет и пеших
        переходов, соревнований по рукопашному бою.
      </p>
      <p class="history-page__item">
        Необходимость подготовки физически крепких молодых рабочих, готовых к производительному
        труду и обороне Родины, вкупе с расширением массовой физкультурно-спортивной работы в
        профессиональных училищах и школах ФЗО потребовали создания специальной спортивной
        организации. И в разгар одного из тяжелейших сражений Великой Отечественной войны — Курской
        битвы Совет Народных Комиссаров СССР, понимая огромную важность военно-физического
        воспитания рабочей молодежи, 19 июня 1943 г. дал разрешение Главному управлению трудовых
        резервов организовать Всесоюзное добровольное спортивное общество учащихся и работников
        ремесленных и железнодорожных училищ, школ ФЗО и присвоил ему наименование «Трудовые
        резервы». Немногим более месяца спустя был утвержден устав нового спортивного общества и
        образован его Центральный совет. 21 июля 1943 г. и считается днем рождения ВДСО «Трудовые
        резервы».
      </p>
      <p class="history-page__item">
        С самого начала деятельности ВДСО «Трудовые резервы» работало в тесном контакте с
        руководящими государственными органами профессионального образования независимо от того, как
        они в разные годы назывались (Главное управление трудовых резервов при СНК СССР,
        Министерство трудовых резервов, Государственный комитет Совета Министров СССР по
        профессионально-техническому образованию и др.). Например, когда в годы войны (с 1943 г.) и
        в первые послевоенные годы стали функционировать специальные ремесленные училища для детей
        погибших военнослужащих и партизан, а также детей, лишившихся родителей в период фашистской
        оккупации, спортивные организации ВДСО «Трудовые резервы» сразу окружили этих учащихся
        особым вниманием и во многом способствовали ликвидации беспризорности в стране, вовлечению в
        занятия физической культурой и спортом трудных, неблагополучных подростков, воспитанию не
        только хорошо физически подготовленной, но и высоконравственной молодежи. И постоянно
        держали этот вопрос под контролем (в 1977 г., к примеру, было издано специальное
        постановление президиума Центрального совета спортивного общества «О мерах ВДСО «Трудовые
        резервы» по улучшению воспитательной работы и устранению правонарушений среди учащихся
        профтехобразования»).
      </p>
      <h3 class="history-page__year">1959</h3>
      <p class="history-page__item">
        В 1959 г., когда по решению Совета Министров СССР ВДСО «Трудовые резервы» получило статус
        самостоятельной центральной организации страны. Спортивному обществу были переданы все
        спортивные сооружения системы «Трудовых резервов», ему стали нацелено выделяться средства на
        его деятельность. Проведение всех спортивных соревнований осуществлялось за счет
        государственного бюджета. Председателю общества были предоставлены права руководителя
        центрального ведомства страны.
      </p>
      <p class="history-page__item">
        На спортивное общество было возложено не только проведение спортивно-массовой работы с
        учащимися системы профтехобразования, но и ответственность за организацию и качество
        учебного процесса по физическому воспитанию во всех профессионально-технических училищах
        (ПТУ, как они стали называться с 1958 г.) и индустриально-педагогических техникумах.
      </p>
      <p class="history-page__item">
        Физической культуре и военному делу обучали учащихся еще в школах фабрично-заводского
        ученичества. Усиленно готовили физически воспитанников профессиональных училищ и в годы
        Великой Отечественной войны 1941-1945 гг. Наконец, в 1946 г. учебный предмет «Физическое
        воспитание» был введен в программы учебных заведений системы профессионального образования
        как самостоятельная дисциплина. Определены были и основные формы учебной работы, разработана
        и утверждена учебная программа, организационно-методические указания, специальные учебные
        пособия по физическому воспитанию учащихся.
      </p>
      <p class="history-page__item">
        В профессиональных училищах были введены должности инструкторов по физическому воспитанию.
        Для подготовки преподавательских кадров физической культуры для учебных заведений системы
        трудовых резервов еще в 1944 г. в Москве был создан техникум физической культуры и спорта,
        директором которого был назначен А.К.Бондарев, впоследствии первый председатель Федерации
        акробатики СССР (в 80-е годы в стране функционировали уже четыре подобных техникума, которые
        ежегодно оканчивали 600 преподавателей физического воспитания).
      </p>
      <p class="history-page__item">
        Учебный процесс по физическому воспитанию и спортивно-массовая работа во внеурочное время с
        1959 г. всегда занимали ведущее место в физкультурно-спортивной деятельности ВДСО «Трудовые
        резервы» и постоянно совершенствовались.
      </p>
      <h3 class="history-page__year">1964</h3>
      <p class="history-page__item">
        Большое внимание в ВДСО «Трудовые резервы» придавалось научно-методической и организационной
        работе. В 1964 г. при Центральном совете общества под руководством доктора педагогических
        наук В.В.Белиновича был образован научно-методический совет (НМС) ВДСО «Трудовые резервы» по
        физическому воспитанию учащихся ПТУ. Отдел физического воспитания общества при
        непосредственном участии НМС в 9 крупных городах страны организовал опорные пункты
        научно-исследовательской и методической работы, одними из основных задач которых являлись
        разработка системы профессиональной физической подготовки будущих молодых рабочих, обобщение
        и распространение передового опыта работы по развитию физической культуры и массового спорта
        в профессиональных училищах разного профиля.
      </p>
      <h3 class="history-page__year">1967</h3>
      <p class="history-page__item">
        В 1967 г. состоялась I Всесоюзная научно-практическая конференция ВДСО «Трудовые резервы»,
        созванная по итогам исследований 1965 и 1966 гг. по теме «Физическое воспитание учащихся
        профессионально-технических училищ». О разнообразии тем, исследуемых в то время опорными
        пунктами, свидетельствуют наименования некоторых из 20 основных докладов: «Принципы
        классификации учебного материала и структура программы по физическому воспитанию в ПТУ»,
        «Влияние занятий физической культурой и спортом на учебную и общественную жизнь учащихся»,
        «Физическая подготовленность юношей и девушек, поступающих в ленинградские профтехучилища»,
        «Особенности профессиональной деятельности машинистов башенных кранов, определяющие
        прикладную направленность их физического воспитания», «Особенности прикладной физической
        подготовки учащихся-токарей по металлу».
      </p>
      <p class="history-page__item">
        Всесоюзные научно-практические конференции стали проводиться регулярно. И всё больше ученых
        и передовых педагогов стали принимать в них участие. Это помогало сотрудникам спортивного
        общества в методическом обеспечении как урочной, так и внеучебной
        физкультурно-оздоровительной и спортивно-массовой работы в ПТУ.
      </p>
      <h3 class="history-page__year">1971</h3>
      <p class="history-page__item">
        В 1971 г. мастер производственного обучения московского ПТУ № 40, мастер спорта СССР по
        хоккею с мячом В.С.Филиппов за подготовку рабочих кадров для промышленности был удостоен
        звания Герой Социалистического Труда.
      </p>
      <p class="history-page__item">
        Далее, Центральный совет ВДСО «Трудовые резервы» возглавлял Н.А.Никифоров-Денисов. Ни одно
        начинание, ни одно мероприятие, ни одно доброе дело не прошли мимо него. Он всегда четко
        представлял себе перспективы развития «Трудовых резервов» и мог помочь довести до
        логического конца любое дельное предложение, независимо от того, откуда и от кого оно
        исходило. И непременно поддерживал всё, что шло на пользу физической подготовленности и
        жизненному становлению учащихся профтехучилищ.
      </p>
      <p class="history-page__item">
        Особую заботу Н.А.Никифоров-Денисов проявлял о материально-техническом обеспечении
        физкультурно-спортивной работы, в том числе о строительстве многофункциональных спортивных
        комплексов. Будучи одновременно председателем Федерации бокса СССР и президентом
        Международной ассоциации любительского бокса (АИБА), он способствовал развитию бокса и в
        «Трудовых резервах», не забывая при этом и о других видах спорта.
      </p>
      <h3 class="history-page__year">1982 - 88</h3>
      <p class="history-page__item">
        К началу 90-х годов XX века около 40 миллионов воспитанников учебных заведений
        профтехобразования стали значкистами ГТО, около 15 миллионов получили массовые спортивные
        разряды. С 1982 г. учащиеся ПТУ ежегодно участвовал конкурсах на лучшую спортивную группу
        под, девизом «Стартуют все» и на лучшую постановку физкультурно-оздоровительной и
        спортивно-массовой работы в коллективах физкультуры ПТУ
      </p>
      <p class="history-page__item">
        Во всех профессиональных училищах работали спортивные секции. За период с 1947 по 1991 г. в
        местных организациях общества было подготовлено 620 мастеров спорта СССР международного
        класса и 12650 мастеров спорта CCCР.
      </p>
      <p class="history-page__item">
        По сути дела каждое профессиональное училище имело материально-техническую базу для
        проведения урочной и вне учебной физкультурно-спортивной работы. Например, в 1986 г. учебные
        заведения профтехобразования располагали почти 23 тысячами спортивных сооружений, в том
        числе 54 стадионами, 95 плавательными бассейнами, 5125 спортивными залами, 11402
        баскетбольными и волейбольными площадками, 249£ футбольными полями, 3324 стрелковыми тирам и
        рядом других сооружений.
      </p>
      <p class="history-page__item">
        Добрую память оставили о себе спортсмены системы профтехобразования своими массовыми
        показательными выступлениями на торжественных мероприятиях, посвященных Всесоюзному дню
        физкультурника, Спартакиадам народов СССР и РСФСР, участием в праздничных парадах и
        демонстрациях. Посчастливилось им выступать и в процедурах открытия и закрытия в Москве XXII
        Олимпийских игр в 1980 г. и Международных игр Доброй воли в 1986 г.
      </p>
      <p class="history-page__item">
        Особая роль в укреплении здоровья учащихся профессиональных училищ и подготовке талантливых
        молодых спортсменов принадлежит Всесоюзным спартакиадам учащихся трудовых резервов (затем
        они стали называться Всесоюзными спартакиадами учащихся учебных заведений
        профтехобразования). С 1955 по 1987 г. состоялось 18 таких спартакиад. Вначале они
        проводились ежегодно, а потом промежутки между ними были увеличены до двух и трех лет.
        Организовывались спартакиады в несколько этапов: от соревнований непосредственно в каждом
        училище до всесоюзного финала, в котором принимали участие сборные команды союзных
        республик, Москвы и Ленинграда. Количество видов спорта в программе спартакиад всё время
        увеличивалось. Финалы проходили в столицах союзных республик и крупнейших городах страны. В
        1975 и 1976 гг. дважды были организованы также Всесоюзные спортивные игры учащихся
        профтехобразования.
      </p>
      <p class="history-page__item">
        Сильнейшие спортсмены ВДСО «Трудовые резервы» успешно выступали на крупнейших всесоюзных и
        международных соревнованиях как в индивидуальных видах состязаний, так и в составе сборных
        команд страны. Чемпионами и обладателями Кубка СССР стали 1002 спортсмена «Трудовых
        резервов», чемпионами Европы — 308, чемпионами мира и победителями Кубков мира — 267. С 1952
        по 1992 г, воспитанники спортивного общества завоевали на летних и зимних Олимпийских играх
        32 золотые, 13 серебряных и 26 бронзовых медалей.
      </p>
      <p class="history-page__item">
        Первыми победителями юношеского первенства Советского Союза стали в 1944 г., боксеры
        В.Меднов,А.Сурков и И.Козлов. Мотоциклисты Е.Грингаут, Л.Троцевская и С.Овчинкин в 1945 г.
        первыми из взрослых спортсменов завоевали звания чемпионов СССР. В том же году гимнастка
        Т.Огурцова (Кузнецова) в составе сборной команды Москвы тоже победила в чемпионате СССР и
        первой среди своих коллег из «Трудовых резервов» удостоилась звания «Мастер спорта». В 1949
        г. стрелок М.Иткис первым из спортсменов общества установил мировой рекорд. В 1953 г. боксер
        В.Енгибарян победил на чемпионате Европы. В 1952 г. на XV Олимпийских играх в составе
        сборной команды страны участвовали семь спортсменов ВДСО «Трудовые резервы», и четверо из
        них получили олимпийские награды: стрелок А.Богданов и борец Ш.Сафин — золотые, боксеры
        В.Меднов— серебряную, А.Перов — бронзовую. Чемпионами Олимпийских игр в 1956-1992 гг. были
        также воспитанники «Трудовых резервов» боксеры В.Енгибарян, О.Григорьев, С.Степашкин,
        Д.Позняк, В.Кузнецов, борцы М.Цалкаламанидзе, Н.Соловьев, А.Фадзаев, М.Мамиашвили,
        Л.Хабелов, С.Мартынов, А.Азизов, гимнастки А.Кошелъ, С.Захарова, С.Богинская, В.Щербо,
        гребцы А.Дегтярев, И.Клименгьев, легкоатлеты В.Цыбуленко, В.Муравьев, Н.Чернецкий,
        Л.Чернова, В.Лыхо, прыгунья в воду И.Лашко.
      </p>
      <p class="history-page__item">
        Прославились «Трудовые резервы» и своими тренерами. Это - З.О.Аристакисян, Б.Н.Греков,
        Б.С.Денисов, А.И.Булычев, А.Ф.Лавринович, Г.И.Шевалдышев, А.А.Чеботарев (бокс),
        И.Р.Иохельсон, Т.С.Ломова (стрельба), Д.К.Осипов, М.Г.Дмитриева, К.С.Каракашьянц (спортивная
        гимнастика), А.Ф.Блюмфельд, П.Г.Капулер (акробатика), О.Н.Зикеева (мотоспорт), В.В.Новожилов
        (водные лыжи), Р.Д.Люлько, Э.С.Рохлин, З.П.Носкова (легкая атлетика), К.М.Дидегкаев,
        И.Х.Цомартов (борьба), А.С.Блюменталь (водное поло), Е.А.Облыгина (художественная
        гимнастика) и многие другие.
      </p>
      <p class="history-page__item">
        Отличную физическую и спортивную закалку дали «Трудовые резервы» первому космонавту планеты
        Земля, Герою Советского Союза Ю.А.Гагарину и его товарищу по покорению космоса дважды Герою
        Советского Союза П.Р.Поповичу. Знаменательно, что воспитанником еще довоенной школы
        фабрично-заводского ученичества был и отец советской практической космонавтики дважды Герой
        Социалистического Труда, лауреат Ленинской премии СССР С.П.Королев. В 1988 г. постановлением
      </p>
      <p class="history-page__item">
        Правительства СССР от 29 апреля 1988 г. «Об организации и структуре управления народным
        образованием в стране» на ВДСО «Трудовые резервы» было возложено руководство
        спортивно-массовой работой не только в профучилищах, но и в общеобразовательных школах. В
        июле 1991 г. ВДСО «Трудовые резервы» было зарегистрировано в Министерстве юстиции СССР как
        общественная организация. Финансирование деятельности спортивного общества стало проводиться
        за счет бюджетов союзных республик, в которых были учреждены самостоятельные
        физкулътурно-спортивные организации системы профтехобразования. В России такой организацией
        стала созданная 12 апреля 1991 г. на базе Российского совета ВДСО «Трудовые резервы» ФСО
        «Юность России».
      </p>
      <h3 class="history-page__year">1992</h3>
      <p class="history-page__item">
        Однако в 1992 г. в связи с распадом СССР было признано нецелесообразным существование
        Всесоюзного добровольного спортивного общества «Трудовые резервы» и его Центрального совета.
        10 июня 1992 г. ликвидационный съезд решил с 1 октября 1992 г. прекратить деятельность ВДСО
        «Трудовые резервы». Его своеобразным преемником в суверенной Российской Федерации явилась
        физкультурно-спортивная организация «Юность России».
      </p>
      <p class="history-page__item">
        Почти за 50 лет своей деятельности ВДСО «Трудовые резервы» внесло огромный вклад в
        военно-физическое и патриотическое воспитание советской молодежи, в формирование здорового,
        физически крепкого и умелого пополнения рабочего класса, всегда готового
        высококвалифицированным трудом неустанно повышать могущество своей великой Родины. Весома
        роль «Трудовых резервов» и в подготовке резерва для спорта высших достижений. За полувековую
        историю деятельности ВДСО «Трудовые резервы» подготовило:
      </p>
      <p class="history-page__item">- значкистов комплекса ГТО - около 40 миллионов;</p>
      <p class="history-page__item">- значкистов «Турист СССР» - около 15 миллионов;</p>
      <p class="history-page__item">- спортсменов массовых разрядов - свыше 52 миллионов;</p>
      <p class="history-page__item">
        - мастеров спорта СССР- 12650, мастеров спорта международного класса - 620.
      </p>
      <p class="history-page__item">
        Чемпионами мира стали 267 спортсменов ВДСО «Трудовые резервы » и 16 выпускников ПТУ.
      </p>
      <p class="history-page__item">
        Чемпионами Европы стали 308 спортсменов Общества и 21 выпускник ПТУ.
      </p>
      <p class="history-page__item">
        Чемпионами и победителями первенств СССР стали 1002 спортсмена Общества.
      </p>
      <p class="history-page__item">
        Заслуженными мастерами спорта СССР стали 64 спортсмена Общества.
      </p>
      <p class="history-page__item">
        С момента основания, до мая 2016 года Российский комитет «Юность России» возглавлял
        профессор А.П.Зотов, подобрав специалистов в Российский и региональные комитеты
        физкультурно-спортивной организации. И многое удалось сделать.
      </p>
      <p class="history-page__item">
        Одно из этих важнейших дел — проведение совместно с Федеральным агентством по образованию
        Российской Федерации и Федеральным агентством по физической культуре и спорту, Российского
        смотра физической подготовленности учащейся молодежи (профессионально-технические училища,
        профессиональные лицеи, колледжи и техникумы, общеобразовательные школы). Уже состоялось 22
        смотра, и с каждым новым из них растет число участников (в I смотре — 250 тысяч, в XXII –
        свыше 2 миллионов).
      </p>
      <h3 class="history-page__year">2016 - 2017</h3>
      <p class="history-page__item">
        В 2016 - 2017 учебном году ОГФСО «Юность России» проводит уже ставший традиционным, XII
        Всероссийский конкурс «Мастер педагогического труда по внеучебным формам
        физкультурно-оздоровительной и спортивной работы». На различных этапах конкурса определяют
        лучших физкультурно-спортивных педагогов в 6 номинациях: дошкольные образовательные
        учреждения; общеобразовательные школы; образовательные учреждения для детей-сирот и детей,
        оставшихся без попечения родителей; учебные заведения начального и среднего
        профессионального образования; учреждения дополнительного образования детей и общественные
        физкультурно-спортивные объединения. В финалах конкурса, кроме этих номинаций, проходят
        состязания педагогов и по учебной работе. ОГФСО «Юность России» постоянно заботится и о
        создании специализированных физкультурно-спортивных юношеских и молодежных клубов,
        действующих на демократической основе, следуя принципам самоуправления, общедоступности,
        преемственности.
      </p>
      <p class="history-page__item">
        Большое место в деятельности Объединения «Юность России» занимают также выявление, обобщение
        и пропаганда передового опыта работы по физическому воспитанию, организации разумного
        физкультурного досуга детей и молодежи в учебных заведениях и спортивных клубах.
      </p>
      <p class="history-page__item">
        Дает добрые плоды и работа по подготовке спортивных резервов. Объединение «Юность России»
        подготовило немало очень сильных спортсменов, достойно представляющих нашу страну на
        международной арене. Среди них чемпионы Европы, мира, Олимпийских игр лыжница Л.Лазутина,
        штангист А. Чемеркин, борец В.Самургашев, гребец В.Денисов, конькобежец Д.Шепель, боксеры
        К.Цзю, 0.Сайтов, С.Ибрагимов, А. Поветкин, легкоатлеты С.Мастеркова, Ю.Борзаковский,
        фристайлистка Е.Кожевникова, специалистки художественной гимнастики А.Зарипова, Е.Мурзина и
        другие.
      </p>
      <p class="history-page__item">
        Распоряжением Правительства Российской Федерации от 11 июля 2002 г. ФСО «Юность России»
        получила новый статус. Теперь ее наименование — «Общественно-государственное
        физкультурно-спортивное объединение (ОГФСО) «Юность России». Это значительно расширило его
        возможности и функции.
      </p>
      <p class="history-page__item">
        В мае 2016 года решением Комитета ОГФСО «Юность России» председателем Объединения избран
        Заслуженный тренер Российской Федерации, доктор педагогических наук Алан Михайлович Абаев.
      </p>
      <p class="history-page__item">
        Под его председательством происходит масштабное развитие объединения (74 региональных
        отделений в субъектах Российской Федерации), укрепляется и систематизируется база
        объединения, намечены новые пути развития, развиваются партнерские отношения, укрепляются
        региональные связи и международное сотрудничество, проводятся физкультурно-спортивные
        мероприятия, активно развиваются приоритетные национальные виды спорта, создаются новые
        всероссийские спортивные объединения, ведется активная работа с федеральными органами
        исполнительной власти России, научная работа, внедряются инновационные технологии.
      </p>
      <p class="history-page__item">
        10 октября 2016 года во Владимире, в рамках «VI Международного форума «Россия – спортивная
        держава», подписано соглашение о создании общественного объединения Всероссийская
        Студенческая лига самбо, которая охватит все учебные организации среднего профессионального
        образования. Учредителями Лиги Самбо стали три организации: Общественно-государственное
        физкультурно-спортивное объединение «Юность России» , «Всероссийская федерация самбо»,
        «Российский студенческий спортивный союз». Президентом общественной организации избран
        заместитель министра образования и науки Российской Федерации Вениамин Шаевич Каганов.
      </p>
      <p class="history-page__item">
        Продолжая славные традиции ВДСО «Трудовые резервы», ОГФСО «Юность России» считает своим
        долгом и дальше активно участвовать в реализации государственной политики в области
        физической культуры и спорта, в сохранении и укреплении здоровья детей и молодежи,
        работников системы образования.
      </p> -->
    </div>
    <partners-block />
  </main>
</template>

<script>
import PartnersBlock from '@/blocks/PartnersBlock.vue';
import BreadCrumbs from '@/components/BreadCrumbs.vue';

export default {
  name: 'SatutePageView',
  components: {
    PartnersBlock,
    BreadCrumbs,
  },
  data() {
    return {
      content: null,
      title: null,
    };
  },
  mounted() {
    document.title = `${process.env.VUE_APP_TITLE} - История`;

    this.getData();
  },
  methods: {
    getData() {
      this.$axios
        .get(`https://sportunros.ru/wp-json/wp/v2/posts/1542`)
        .then((response) => {
          this.title = response.data.title.rendered;
          this.content = response.data.acf.text;
        })
        .catch((e) => console.error(e));
    },
  },
  computed: {},
};
</script>

<style lang="scss">
.history-page {
  text-align: left;
  &__title {
    &:before {
      margin-right: 16px;
      top: -2px;
      background: $green;
    }
  }

  &__inner-wrapper {
    margin-top: 72px;
  }

  &__subtitle {
    margin-bottom: 32px;
    margin-top: 40px;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    text-transform: uppercase;
  }

  &__item {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: $par;
    margin-bottom: 24px;
  }

  &__img {
    width: 100%;
    border-radius: 8px;
  }

  &__year {
    color: $blue;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;

    padding-left: 36px;
    position: relative;
    margin: 40px 0 32px;

    &:before {
      position: absolute;
      content: '';
      width: 20px;
      height: 3px;
      background: $blue;
      top: 50%;
      left: 5px;
    }
  }
}
</style>
